import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import { assignWorkHistoryId } from '../../redux/actions';

import Server from '../../function/server';
import Load from '../../util/load';

class WorkHistory extends Component {

  state = { data: []}

  componentDidMount() {
    let id = this.props.workStatusId;
    if(!isNaN(id)) {
      this.setState({ id }, this.getHistory)
    }
  }

  getHistory = () => {
    let url = Server() + "workhistory/getallbywork.php";
    let data = { id: this.state.id };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading History"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      this.setState({data});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    let { assignWorkHistoryId } = this.props;
    let data = this.state.data.map((h) => {
      return(
        <MDBTableBody>
          <tr>
            <td>{h.id}</td>
            <td>{h.content}</td>
            <td>{h.start_date.split(" ")[0]}</td>
            <td>{h.next_workdate.split(" ")[0]}</td>
            <td>{h.created_by}</td>
            <td>{h.timestamp}</td>
            <th><Link to="/work-status/history/edit" onClick={() => assignWorkHistoryId(h.id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></th>
          </tr>
        </MDBTableBody>
      )
    })
    return(
      <div style={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to="/work-status/history/add" style={{float: 'right', marginBottom: 20}} onClick={() => assignWorkHistoryId(0)}>
          <Button icon labelPosition='left' primary><Icon name='history' />Add History</Button>
        </Link>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>ID</th>
                <th>Content</th>
                <th>Start Date</th>
                <th>Next Working Date</th>
                <th>Created BY</th>
                <th>Added On</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  workStatusId: state.workStatusId,
});

const mapDispatchToProps = (dispatch) => ({
  assignWorkHistoryId: (id) => dispatch(assignWorkHistoryId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkHistory);
