import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddCustomerField extends Component {

  state = { data: [] }

  componentDidMount() {
    let id = this.props.customerId;
    if(!isNaN(id)) {
      this.setState({ id });
    }
    this.getFields();
  }

  handleChange = (type) => (e, { value }) => {
    this.setState({[type]: value});
  }

  getFields = () => {
    let url = Server() + 'field/getallbyshop.php';
    let data = { id: Shop().id, customer_id: this.props.customerId };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Loading Fields"});
    }, success: (data) => {
      data = JSON.parse(data);
      let x = {};
      data.map((d) => {
        x[d.id] = d.value;
      });
      this.setState({ data, ...x, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  add = () => {
    let fieldData = [];
    this.state.data.map((s) => { fieldData.push({[s.id]: this.state[s.id]}); });
    let data = { fields: fieldData, created_by: UserId(), customer_id: this.props.customerId };
    data.data = null;
    let url = Server() + 'editotherfields/edit.php';
    $.ajax({ url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Update Fields"})
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert("Update Successfully");
        this.setState({ fieldData: "", load: false });
      } else {
        alert("Failed to Update"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    let data = [];
    for (var i = 0; i < this.state.data.length; i += 3) {
      let x = this.state.data[i];
      let y = null;
      let z = null;
      try {
        y = this.state.data[i + 1];
      } catch (e) {}
      try {
        z = this.state.data[i + 2];
      } catch (e) {}
      data.push(
        <Form.Group>
          <Form.Input fluid label={x.field} placeholder={x.field} value={this.state[x.id]}
            onChange={this.handleChange(x.id)} width={5}/>
          {y && <Form.Input fluid label={y.field} placeholder={y.field} value={this.state[y.id]}
            onChange={this.handleChange(y.id)} width={5}/>}
          {z && <Form.Input fluid label={z.field} placeholder={z.field} value={this.state[z.id]}
            onChange={this.handleChange(z.id)} width={5}/>}
        </Form.Group>
      );
    }
    return (
      <div style={{ margin: 20 }}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          {data}
        </Form>
        <center>
          <Button primary onClick={this.add}>Save</Button>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  customerId: state.customerId,
});

export default connect(mapStateToProps)(AddCustomerField);
