import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $  from 'jquery';
import { Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddBank extends Component {

  state = {}

  componentDidMount() {
    let { bankId, customerId } = this.props;
    if(!isNaN(bankId) && bankId > 0) {
      this.getBank(bankId);
    }
    if(!isNaN(customerId) && customerId > 0) {
      this.setState({ customerId });
    }
  }

  getBank = (id) => {
    let url = Server() + "bankinfo/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {id, bank_name, account_type, account_number, payee_name, ifsc_code, upi} = c;
      this.setState({id, bank_name, account_type, account_number, payee_name, ifsc_code, upi, edit: true});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  handleChange = (type) => (e) => {
    this.setState({[type]: e.target.value});
  }

  add = () => {
    let { bank_name, account_type, account_number, payee_name, ifsc_code, upi, customerId } = this.state;
    if(!bank_name || bank_name.length < 1 || !account_number || account_number.length < 1 || !account_type ||
      account_type.length < 1 || !payee_name || payee_name.length < 1 || !ifsc_code || ifsc_code.length < 1
       || !upi || upi.length < 1) {
      alert("Invalid Bank Information"); return;
    }
    let data = {customer_id: customerId , bank_name, account_type, account_number, payee_name, ifsc_code, upi, created_by: UserId()};
    let url = Server() + "bankinfo/add.php";
    if(this.state.edit) {
      data = {id: this.state.id, bank_name, account_type, account_number, payee_name, ifsc_code, upi};
      url = Server() + "bankinfo/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding Data"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Bank Information Added Successfully");
        this.setState({bank_name: "", account_type: "", account_number: "", payee_name: "", ifsc_code: "", upi: "", load: false});
      } else {
        alert("Failed to Add");
      }
    }, error: (err) => {
      console.log(err);
      alert("error");
    }})
  }

  render() {
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <Form.Group style={{marginLeft: 50}}>
            <Form.Input fluid label='Bank Name' placeholder='Enter Bank Name'
              value={this.state.bank_name} onChange={this.handleChange("bank_name")} width={5}/>
            <Form.Input fluid label='Account Type' placeholder='Enter Account Type'
              value={this.state.account_type} onChange={this.handleChange("account_type")} width={5}/>
            <Form.Input fluid label='Account Number' placeholder='Enter Account Number'
              value={this.state.account_number} onChange={this.handleChange("account_number")} width={5}/>
          </Form.Group>
          <Form.Group style={{marginLeft: 50}}>
            <Form.Input fluid label='Payee Name' placeholder='Enter Payee Name'
              value={this.state.payee_name} onChange={this.handleChange("payee_name")} width={5}/>
            <Form.Input fluid label='IFSC Code' placeholder='Enter IFSC Code'
              value={this.state.ifsc_code} onChange={this.handleChange("ifsc_code")} width={5}/>
            <Form.Input fluid label='UPI' placeholder='Enter UPI'
              value={this.state.upi} onChange={this.handleChange("upi")} width={5}/>
          </Form.Group>
          <center>
            <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  bankId: state.bankId, customerId: state.customerId
});

export default connect(mapStateToProps, null)(AddBank);
