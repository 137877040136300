const initialState = { customerId: 0, bankId: 0, primaryId: 0, secondaryId: 0, enquiryId: 0, workTypeId: 0,
                       followUpId: 0, followHistoryId: 0, workStatusId: 0, workHistoryId: 0, controlId: 0, groupId: 0, fieldId: 0,
                       contactId: 0, alternateId: 0, shop: null };

function addReducer(state = initialState, action) {
  switch (action.type) {
    case 'ASSIGN_CUSTOMER_ID': return { ...state, customerId: action.customerId };
    case 'ASSIGN_BANK_ID': return { ...state, bankId: action.bankId };
    case 'ASSIGN_PRIMARY_ID': return { ...state, primaryId: action.primaryId };
    case 'ASSIGN_SECONDARY_ID': return { ...state, secondaryId: action.secondaryId };
    case 'ASSIGN_ENQUIRY_ID': return { ...state, enquiryId: action.enquiryId };
    case 'ASSIGN_WORKTYPE_ID': return { ...state, workTypeId: action.workTypeId };
    case 'ASSIGN_FOLLOWUP_ID': return { ...state, followUpId: action.followUpId };
    case 'ASSIGN_FOLLOWHISTORY_ID': return { ...state, followHistoryId: action.followHistoryId };
    case 'ASSIGN_WORKSTATUS_ID': return { ...state, workStatusId: action.workStatusId };
    case 'ASSIGN_WORKHISTORY_ID': return { ...state, workHistoryId: action.workHistoryId };
    case 'ASSIGN_CONTROL_ID': return { ...state, controlId: action.controlId };
    case 'ASSIGN_GROUP_ID': return { ...state, groupId: action.groupId };
    case 'ASSIGN_FIELD_ID': return { ...state, fieldId: action.fieldId };
    case 'ASSIGN_CONTACT_ID': return { ...state, contactId: action.contactId };
    case 'ASSIGN_ALTERNATE_ID': return { ...state, alternateId: action.alternateId };
    default: return { ...state };
  }
}

export default addReducer;
