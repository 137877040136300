import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import $ from 'jquery';
import { connect } from 'react-redux';

import Server from '../../function/server';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddWorkHistory extends Component {

  state = {}

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  componentDidMount() {
    let { workHistoryId, workStatusId } = this.props;
    if(!isNaN(workHistoryId) && workHistoryId > 0) {
      this.getHistory(workHistoryId);
    }
    if(!isNaN(workStatusId) && workStatusId > 0) {
      this.setState({ workStatusId });
    }
  }

  getHistory = (id) => {
    let url = Server() + "workhistory/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {id, workstatus_id, content, start_date, next_workdate} = c;
      start_date= start_date.split(" ")[0];
      next_workdate= next_workdate.split(" ")[0];
      this.setState({id, workStatusId: workstatus_id, content, start_date, next_workdate, edit: true});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  add = () => {
    let {workStatusId, content, start_date, next_workdate} = this.state;
    if ( !content || content.length < 1 || !start_date || start_date.length < 1 || !next_workdate || next_workdate.length < 1 ) {
      alert("Invalid Information"); return;
    }
    let data = {workstatus_id: workStatusId, content, start_date, next_workdate, created_by: UserId()};
    let url = Server() + "workhistory/add.php";
    if (this.state.edit) {
      data = {id: this.state.id, content, start_date, next_workdate};
      url = Server() + "workhistory/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Adding"})
    }, success: (data) => {
      if (data == "1" || data == "true") {
        alert(this.state.edit ? "Updated successfully" : "Work History Added Successfully");
        this.setState({content: "", start_date: "", next_workdate: "", load: false});
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error")
    }})
  }

  render() {
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <Form.Input fluid label='Content' placeholder='Enter content'
            value={this.state.content} onChange={this.handleChange("content")} width={6}/>
          <Form.Group>
            <Form.Input fluid label='Start Date' placeholder='Enter starting Date' type="date"
               value={this.state.start_date} onChange={this.handleChange("start_date")} width={4}/>
             <Form.Input fluid label='Next Work Date' placeholder='Enter Next Work Date' type="date"
               value={this.state.next_workdate} onChange={this.handleChange("next_workdate")} width={4}/>
          </Form.Group>
          <center>
            <Button color= "blue" primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  workHistoryId: state.workHistoryId, workStatusId: state.workStatusId
});

export default connect(mapStateToProps, null)(AddWorkHistory);
