import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Segment, Header, Button, Image, Modal, Icon, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../function/server';
import Load from '../util/load';

class CustomerDetails extends Component {

  state = {}

  open = () => {
    let { customer_id } = this.props;
    if (!isNaN(customer_id) && customer_id > 0) {
      this.getCustomer(customer_id);
    }
  }

  getCustomer = (customerId) => {
    let url = Server() + "customer/getbyid.php";
    $.ajax({url, method: "POST", data: {id: customerId}, success: (data) => {
      let c = JSON.parse(data);
      let {id, name, mobile, alt_mobile, mail, address, primary_category, secondary_category, enquiry_type, business_name, primary_category_name, secondary_category_name,
          enquiry_type_name, group_name, gstin, pan, aadhar, status, location, referred_by} = c;
      this.setState({id, name, mobile, alt_mobile, mail, address, primary_category, primary_category_name, secondary_category_name, enquiry_type_name, group_name,
         secondary_category, enquiry_type, business_name, gstin, pan, aadhar, status, location, referred_by, openDialog: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  close = () => {
    this.setState({openDialog: false});
  }

  render() {
    const { openDialog, dimmer } = this.state;
    return (
      <div style={{margin: 10}}>
          <div style={{margin: 10, color:'#17a2b8'}} onClick={this.open}>{this.props.name}</div>
            <Modal dimmer={dimmer} open={openDialog} onClose={this.close} basic style={{width:700, height:450, marginTop: 100, marginLeft:340}}>
                <Modal.Content>
                    <Header style={{color: 'white'}}>{this.props.name.toUpperCase()}  DETAILS</Header>
                  <Modal.Description>
                    <center>
                      <table borderless  width='80%'>
                        <tr>
                          <td style={{height: 50}}>Mobile:</td><td>{this.state.mobile}</td>
                          <td style={{width: 50, height: 50}}>GSTIN:</td><td>{this.state.gstin}</td>
                        </tr>
                        <tr>
                          <td style={{height: 50}}>Alternate Mobile:</td><td>{this.state.alt_mobile}</td>
                          <td style={{height: 50}}>PAN:</td><td>{this.state.pan}</td>
                        </tr>
                        <tr>
                          <td style={{height: 50}}>Mail:</td><td>{this.state.mail}</td>
                          <td style={{height: 50}}>Aadhar:</td><td>{this.state.aadhar}</td>
                        </tr>
                        <tr>
                          <td style={{height: 50}}>Address:</td><td>{this.state.address}</td>
                          <td style={{height: 50}}>Location:</td><td>{this.state.location}</td>
                        </tr>
                        <tr>
                          <td style={{height: 50}}>Business Name:</td><td>{this.state.business_name}</td>
                          <td style={{height: 50}}>Referred BY:</td><td>{this.state.referred_by}</td>
                        </tr>
                      </table>
                    </center>
                  </Modal.Description>
                </Modal.Content>
                  <Button style={{float: "right", marginTop: 35, marginRight:30}}
                     color='orange'
                     content="Exit"
                     onClick={this.close}
                  />
            </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  customerId: state.customerId,
});

export default connect(mapStateToProps, null)(CustomerDetails);
