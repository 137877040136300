import React from 'react';
import $ from 'jquery';

import Server from '../function/server';
import Shop from '../function/shop'
import UserId from '../function/userid';
import Dashboard from './dashboard';

export default class Home extends React.Component {

  state = {widget: {client: 'Loading', pendingWorks: 'Loading', pendingFollowups: 'Loading', users: 'Loading'}, graphData: []}

  componentDidMount() {
    this.getDashboardData();
    this.getGraphData();
  }

  getDashboardData = () => {
    let url = Server() + "users/getdashboarddata.php";
    let data = { id: UserId(), shop_id: Shop().id };
    $.ajax({url, method: "POST", data, success: (data) => {
      data = JSON.parse(data);
      let widget = {
        client: data.your_customers + " (" + data.customers + ")",
        pendingWorks: data.your_pending_workstatus + " (" + data.pending_workstatus + ")",
        pendingFollowups: data.your_pending_followups + " (" + data.pending_followups + ")",
        users: data.users
      };
      this.setState({ widget });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getGraphData = () => {
    let url = Server() + "users/graphdata.php";
    let data = { id: UserId() };
    $.ajax({url, method: "POST", data, success: (data) => {
      data = JSON.parse(data);
      this.setState({ graphData: data });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    return (
      <div style={{margin: 10}}>
        <Dashboard widget={this.state.widget} graphData={this.state.graphData}/>
      </div>
    );
  }

}
