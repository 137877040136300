import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button, Segment, Header, Image, Modal, Icon, Table} from 'semantic-ui-react';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

export default class CustomerFollow extends Component {

  state = {}

  componentDidUpdate(props) {
    if (props.walkin != this.props.walkin) {
      this.setState({openDialog: this.props.walkin});
    }
  }

  handleChange = (type) => (e, { value }) => {
    this.setState({[type]: value});
  }

  add = () => {
    let { customer } = this.state;
    let { name, mobile, mail, location, referred_by } = this.state;
    let status = this.props.status;
    if (!mail || mail.length < 1) mail = 0;
    if (!referred_by || referred_by.length < 1) referred_by = 0;
      if (!name || name.length < 1 || !mobile || mobile.length < 1 || !location || location.length < 1 ) {
        alert("Invalid Information"); return;
      }
      let data = { shop_id: Shop().id, name, mobile, mail, referred_by, status, location, created_by: UserId()};
      let url = Server() + "customer/customeradd.php";
      $.ajax({ url, method: "POST", data, beforeSend: () => {
        this.setState({ load: true, message: "Adding Data"});
      }, success: (data) => {
        if( !isNaN(data) || data !== "0" ) {
          alert("Customer Added Successfully");
          customer = {id: data, name, mobile};
          this.props.appendCustomer(customer);
          this.close();
          this.setState({ name: "", mobile: "", mail: "", location: "", referred_by: "", customer, load: false });
        } else {
          alert("Failed to Add"); console.log(data);
        }
      }, error: (e) => {
        console.log(e);
        alert("Error");
      }})
  }

  close = () => {
    this.setState({openDialog: false});
  }

  render() {
    const { openDialog, dimmer } = this.state;
    return (
      <div style={{margin: 20}}>
        <Button icon labelPosition='left' style={{ backgroundColor: '#17a2b8', color: 'white'}} onClick={() => this.setState({openDialog: true})}>
          <Icon name='user' />
          Add Customers
        </Button>
        <Load load={this.state.load} message={this.state.message} />
          <div>
            <div style={{ color:'#17a2b8'}} onClick={this.open}></div>
              <Modal dimmer={dimmer} open={openDialog} onClose={this.close} style={{width: '70%', height:450, marginTop: 100, marginLeft: 200}}>
                <Modal.Content>
                  <Icon name='close' onClick={this.close} />
                  <Modal.Description >
                    <center>
                      <Form style={{marginTop: '5%'}}>
                       <Form.Group widths='equal'>
                         <Form.Input fluid label='Name' placeholder='Enter Customer Name' required
                           value={this.state.name} onChange={this.handleChange("name")} />
                         <Form.Input fluid label='Mobile' placeholder='Enter Mobile Number' required
                           value={this.state.mobile} onChange={this.handleChange("mobile")}/>
                       </Form.Group>
                        <Form.Group widths='equal' style={{marginTop: '5%'}}>
                          <Form.Input fluid label='E-Mail' placeholder='Enter Customer Mail ID'
                            value={this.state.mail} onChange={this.handleChange("mail")} />
                          <Form.Input fluid label='Location' placeholder='Enter Customer Location' required
                            value={this.state.location} onChange={this.handleChange("location")} />
                        </Form.Group>
                        <Form.Group style={{ marginTop: '5%' , marginLeft: '30%'}}>
                          <Form.Input fluid label='Referred BY' placeholder='Enter Referer Name'
                            value={this.state.referred_by} onChange={this.handleChange("referred_by")} width={10}/>
                        </Form.Group>
                        <center>
                          <Button primary onClick={this.add} style={{ marginTop: '5%' }}>Add</Button>
                        </center>
                      </Form>
                    </center>
                  </Modal.Description>
                </Modal.Content>
            </Modal>
        </div>
    </div>
   )
  }
}
