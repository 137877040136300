import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import Load from '../../util/load';

import { assignAlternateId } from '../../redux/actions';

class Alternate extends Component {

  state = { data: []}

  componentDidMount() {
    this.getAlternate();
  }

  getAlternate = () => {
    let url = Server() + 'alternatecategory/getallbyshop.php';
    let data = { id: Shop().id };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Groups"});
    }, success: (data) => {
      data = JSON.parse(data);
      this.setState({ data, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    let { assignAlternateId } = this.props;
    let data = this.state.data.map((a) => {
      return (
        <MDBTableBody>
          <tr>
            <td>{a.id}</td>
            <td>{a.name}</td>
            <td>{a.created_by}</td>
            <td>{a.timestamp}</td>
            <td><Link to="/alternate-categories/edit" onClick={() => assignAlternateId(a.id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></td>
          </tr>
        </MDBTableBody>
      )
    })
    return (
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to ="/alternate-categories/add" style={{float: 'right', marginBottom: 20}} onClick={() => assignAlternateId(0)}>
          <Button icon labelPosition='left' primary><Icon name='user' />Add Alternate Category</Button>
        </Link>
        <center>
          <MDBTable  borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>ID</th>
                <th>Name</th>
                <th>Created By</th>
                <th>timestamp</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            {data}
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignAlternateId: (id) => dispatch(assignAlternateId(id)),
});

export default connect(null, mapDispatchToProps)(Alternate);
