import React, { Component, lazy, Suspense } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Badge,
  Button,
  ButtonDropdown,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  Table,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'

import Widget from './widget2';
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')

function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

var elements = 27;
var data1 = [];
var data2 = [];
var data3 = [];

for (var i = 0; i <= elements; i++) {
  data1.push(random(50, 200));
  data2.push(random(80, 100));
  data3.push(65);
}

const mainChart = {
  labels: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  datasets: [
    {
      label: 'Followups',
      backgroundColor: hexToRgba(brandInfo, 10),
      borderColor: brandInfo,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: data1,
    },
    {
      label: 'Work Status',
      backgroundColor: 'transparent',
      borderColor: brandSuccess,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: data2,
    },
  ],
};

const mainChartOpts = {
  tooltips: {
    enabled: false,
    custom: CustomTooltips,
    intersect: true,
    mode: 'index',
    position: 'nearest',
    callbacks: {
      labelColor: function(tooltipItem, chart) {
        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
      }
    }
  },
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false,
        },
      }],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 5,
          stepSize: Math.ceil(250 / 5),
          max: 250,
        },
      }],
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    },
  },
};

class Dashboard extends Component {

  getFollowUpData = () => {

  }

  getLabels = () => {
    let labels = [];
    let { graphData } = this.props;
    graphData.map((gd) => labels.push(gd.type));
    labels = labels.filter((a, b) => labels.indexOf(a) === b);
    return labels;
  }

  processFollowUpData = () => {
    let { graphData } = this.props, data = [], max = 0;
    for (var i = 0; i < mainChart.labels.length; i++) {
      let label = mainChart.labels[i], b = true;
      for (var j = 0; j < graphData.length; j++) {
        if (graphData[j].type === label && graphData[j].data === "1") {
          if (graphData[j].count > max) max = graphData[j].count;
          data.push(graphData[j].count); b= false;
        }
      }
      if (b) data.push(0);
    }
    mainChart.datasets[0].data = data;
    return max;
  }

  processWorkStatus = () => {
    let { graphData } = this.props, data = [], max = 0;
    for (var i = 0; i < mainChart.labels.length; i++) {
      let label = mainChart.labels[i], b = true;
      for (var j = 0; j < graphData.length; j++) {
        if (graphData[j].type === label && graphData[j].data === "2") {
          if (graphData[j].count > max) max = graphData[j].count;
          data.push(graphData[j].count); b= false;
        }
      }
      if (b) data.push(0);
    }
    mainChart.datasets[1].data = data;
    return max;
  }

  render() {
    let {client, pendingWorks, pendingFollowups, users} = this.props.widget;
    mainChart.labels = this.getLabels();
    let max = this.processFollowUpData(), max2 = this.processWorkStatus();
    mainChartOpts.scales.yAxes[0].ticks.max = (max > max2) ? parseFloat(max) : parseFloat(max2);
    return (
      <div className="animated fadeIn">
        <Row style={{marginBottom: 10}}>
          <Col xs="12" sm="6" lg="3">
            <Widget header={client} mainText="Clients" icon="fa fa-cogs" color="primary" footer link="/customers/active" />
          </Col>

          <Col xs="12" sm="6" lg="3">
            <Widget header={pendingFollowups} mainText="Pending Enquiry" icon="fa fa-bell" color="warning" footer link="/customer-enquiry" />
          </Col>

          <Col xs="12" sm="6" lg="3">
            <Widget header={pendingWorks} mainText="Pending Works" icon="fa fa-laptop" color="danger" footer link="/work-status" />
          </Col>

          <Col xs="12" sm="6" lg="3">
            <Widget header={users} mainText="Users" icon="fa fa-cogs" color="info" footer link="/users" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <CardTitle className="mb-0">Enquiry and Work Status</CardTitle>
                    <div className="small text-muted">Last 30 Days</div>
                  </Col>
                </Row>
                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                  <Line data={mainChart} options={mainChartOpts} height={300} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
