import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon, Form } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class Add extends Component {

  state = {}

  componentDidMount() {
    let { alternateId } = this.props;
    if (!isNaN(alternateId) && alternateId > 0) {
      this.getAlternate(alternateId);
    }
  }

  getAlternate = (id) => {
    let url = Server() + "alternatecategory/getbyid.php";
    $.ajax({ url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({ load: true, message: "Loading"})
    }, success: (data) => {
      let c = JSON.parse(data);
      let { name, id } = c;
      this.setState({ name, id, edit: true, load: false})
    }, error: (e) => {
      console.log(e);
      alert("Error")
    }})
  }

  handleChange = (type) => (e) => {
    this.setState({[type]: e.target.value});
  }

  add = () => {
    let { name } = this.state;
    if (!name || name.length < 1) {
      alert("Invalid Information"); return;
    }
    let data = {shop_id: Shop().id, name, created_by: UserId()};
    let url = Server() + 'alternatecategory/add.php';
    if (this.state.edit) {
      data = {id: this.state.id, name};
      url = Server() + "alternatecategory/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding Data"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Enquiry Added Successfully");
        this.setState({name: "", load: false});
      } else {
        alert("Failed"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    return (
      <div style={{margin: 30}}>
        <Load load={this.state.load} message={this.state.message} />
        <Form>
          <center>
            <Form.Input label="Name" placeholder="Enter Name" required
              value={this.state.name} onChange={this.handleChange("name")} width={3}/>
            <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  alternateId: state.alternateId,
});

export default connect(mapStateToProps, null)(Add);
