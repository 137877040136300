import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import Load from '../../util/load';

import { assignGroupId } from '../../redux/actions';

class Group extends Component {

  state = {data: []}

  componentDidMount() {
    this.getGroup();
  }

  getGroup = () => {
    let url = Server() + "group/getallbyshop.php";
    let data = { id: Shop().id };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Groups"});
    }, success: (data) => {
      data = JSON.parse(data);
      this.setState({ data, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    let { assignGroupId } = this.props;
    let data = this.state.data.map((g) => {
      return (
        <MDBTableBody>
          <tr>
            <td>{g.id}</td>
            <td>{g.group_name}</td>
            <td>{g.created_by}</td>
            <td>{g.timestamp}</td>
            <td><Link to="/group/edit" onClick={() => assignGroupId(g.id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></td>
          </tr>
        </MDBTableBody>
      )
    })
    return (
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to ="/group/add" style={{float: 'right', marginBottom: 20}} onClick={() => assignGroupId(0)}>
          <Button icon labelPosition='left' primary><Icon name='user' />Add Group</Button>
        </Link>
        <center>
          <MDBTable  borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>ID</th>
                <th>Group</th>
                <th>Created By</th>
                <th>timestamp</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignGroupId: (id) => dispatch(assignGroupId(id)),
});

export default connect(null, mapDispatchToProps)(Group);
