import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import Load from '../../util/load';

import { assignFieldId } from '../../redux/actions';

class Field extends Component {

  state = { data: [] }

  componentDidMount() {
    this.getField();
  }

  getField = () => {
    let url = Server() + "field/getallbyshop.php";
    let data = { id: Shop().id, customer_id: 0 };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Field"});
    }, success: (data) => {
      data = JSON.parse(data);
      this.setState({ data, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    let { assignFieldId } = this.props;
    let data = this.state.data.map((f) => {
      return (
        <MDBTableBody>
          <tr>
            <td>{f.id}</td>
            <td>{f.field}</td>
            <td>{f.created_by}</td>
            <td>{f.timestamp}</td>
            <td><Link to="/field/edit" onClick={() => assignFieldId(f.id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></td>
          </tr>
        </MDBTableBody>
      )
    })
    return (
      <div style={{ margin: 20 }}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to ="/field/add" style={{float: 'right', marginBottom: 20}} onClick={() => assignFieldId(0)}>
          <Button icon labelPosition='left' primary><Icon name='user' />Add Field</Button>
        </Link>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>Id</th>
                <th>Field</th>
                <th>Created By</th>
                <th>Added On</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignFieldId: (id) => dispatch(assignFieldId(id)),
});

export default connect(null, mapDispatchToProps)(Field);
