import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddField extends Component {

  state = {}

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  componentDidMount() {
    let { fieldId } = this.props;
    if (!isNaN(fieldId) && fieldId > 0) {
      this.getField(fieldId);
    }
  }

  getField = (id) => {
    let url = Server() + "field/getbyid.php";
    $.ajax({url, method: "POST", data: { id }, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let { field, id } = c;
      this.setState({field, id, edit: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  add = () => {
    let { field } = this.state;
    if ( !field || field.length < 1) {
      alert("Invalid Information");
    }
    let data = { shop_id: Shop().id, field, created_by: UserId() };
    let url = Server() + "field/add.php";
    if(this.state.edit) {
      data = { id: this.state.id, field};
      url = Server() + "field/edit.php";
    }
    $.ajax({ url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding Data"});
    }, success: (data) => {
      if( data == "1" || data == "true" ) {
        alert(this.state.edit ? "Updated Successfully" : "Field Added Successfully");
        this.setState({ field: "", load: false});
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    return (
      <div style={{ margin: 10 }}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <center>
            <Form.Input label="Field" placeholder="Enter Field Name"  value={this.state.field}
              onChange={this.handleChange("field")} width={3}/>
            <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fieldId: state.fieldId,
});

export default connect(mapStateToProps, null)(AddField);
