import { ASSIGN_CUSTOMER_ID, ASSIGN_BANK_ID, ASSIGN_PRIMARY_ID, ASSIGN_SECONDARY_ID, ASSIGN_ENQUIRY_ID,
         ASSIGN_WORKTYPE_ID, ASSIGN_FOLLOWUP_ID, ASSIGN_FOLLOWHISTORY_ID, ASSIGN_WORKSTATUS_ID,
         ASSIGN_WORKHISTORY_ID, ASSIGN_CONTROL_ID, ASSIGN_GROUP_ID, ASSIGN_FIELD_ID, ASSIGN_CONTACT_ID,
         ASSIGN_ALTERNATE_ID } from './actiontypes';

export function assignCustomerId(customerId) {
  return { type: ASSIGN_CUSTOMER_ID, customerId };
}

export function assignBankId(bankId) {
  return { type: ASSIGN_BANK_ID, bankId };
}

export function assignPrimaryId(primaryId) {
  return { type: ASSIGN_PRIMARY_ID, primaryId };
}

export function assignSecondaryId(secondaryId) {
  return { type: ASSIGN_SECONDARY_ID, secondaryId };
}

export function assignEnquiryId(enquiryId) {
  return { type: ASSIGN_ENQUIRY_ID, enquiryId };
}

export function assignWorkTypeId(workTypeId) {
  return { type: ASSIGN_WORKTYPE_ID, workTypeId };
}

export function assignFollowUpId(followUpId) {
  return { type: ASSIGN_FOLLOWUP_ID, followUpId };
}

export function assignFollowHistoryId(followHistoryId) {
  return { type: ASSIGN_FOLLOWHISTORY_ID, followHistoryId };
}

export function assignWorkStatusId(workStatusId) {
  return { type: ASSIGN_WORKSTATUS_ID, workStatusId };
}

export function assignWorkHistoryId(workHistoryId) {
  return { type: ASSIGN_WORKHISTORY_ID, workHistoryId };
}

export function assignControlId(controlId) {
  return { type: ASSIGN_CONTROL_ID, controlId };
}

export function assignGroupId(groupId) {
  return { type: ASSIGN_GROUP_ID, groupId };
}

export function assignFieldId(fieldId) {
  return { type: ASSIGN_FIELD_ID, fieldId };
}

export function assignContactId(contactId) {
  return { type: ASSIGN_CONTACT_ID, contactId };
}

export function assignAlternateId(alternateId) {
  return { type: ASSIGN_ALTERNATE_ID, alternateId };
}
