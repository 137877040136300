import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Checkbox, Popup, Select, Icon, Input } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../function/server';
import UserId from '../function/userid';
import Shop from '../function/shop';
import Load from '../util/load';
import Details from '../workstatus/details';

import { assignFollowUpId } from '../redux/actions';

class FollowUp extends Component {

  state = { data: [], status: -1, status: "0", search: ""}

  componentDidMount() {
    this.getFollowUp();
  }

  componentDidUpdate(props) {
    if (props.status != this.props.status) {
      this.getFollowUp();
    }
  }

  handleChange = (type) => (e, data, value) => {
    if (type === "viewAll") { this.setState({[type]: data.checked}, this.getFollowUp); return; }
    this.setState({[type]: data.value}, () => {
      if (type !== "search") {
        this.getFollowUp();
      }
    });
  }

  getFollowUp = () => {
    let url = Server() + "followup/getallbyexecutive.php";
    let data = { id: UserId(), shop_id: Shop().id, statuss: this.props.status };
    if (this.state.status >= 0 && !isNaN(this.state.status)) data["status"] = this.state.status;
    if (this.state.viewAll) { data["view_all"] = "1"; }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load:true, message: "Loading FollowUp"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      this.setState({ data });
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  render() {
    let { assignFollowUpId } = this.props;
    let controls = window.localStorage.getItem("controls");
    controls = (!controls || controls.length < 1) ? "" : controls.toLowerCase();
    let statusOptions = [{key: 0, value: "0", text: "Open"}, {key: 1, text: "Processing", value: "1"},
      {key: 2, text: "Closed", value: "2"}];
    let { search } = this.state;
    search = search.trim().toLowerCase();
    let data = this.state.data.map((f) => {
      let status = (f.status === "0") ? "Open" : (f.status === "1") ? "Processing" : "Closed";
      if (!(f.name.toLowerCase().includes(search))) {
        return "";
      }
      return(
          <tr>
            <td>{f.id}</td>
            <td><Details customer_id={f.customer_id} name={f.name}/></td>
            <Popup content={f.description} trigger={<td>{f.title}</td>}/>
            <td>{f.followup_by}</td>
            <td>{f.start_date.split(" ")[0]}</td>
            <td>{f.next_follow_up.split(" ")[0]}</td>
            <td><Link to="/enquiry/history" onClick={() => assignFollowUpId(f.id)}>{f.history}</Link></td>
            <td>{status}</td>
            <td>{f.created_by}</td>
            <td>{f.timestamp}</td>
            <td><Link to="/enquiry/edit" onClick={() => assignFollowUpId(f.id)}>
              <Icon name='edit' />
             </Link></td>
          </tr>
      )
    })
    return(
      <div style={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <div style={{margin:10, float: "left"}}>
          <Input icon='search' placeholder="Search" onChange={this.handleChange("search")} />
        </div>
        <div style={{margin: 10, float: "right"}}>
          {controls.includes("admin") && <Checkbox label="View All" style={{marginRight: 10}} checked={this.state.viewAll}
            onChange={this.handleChange("viewAll")}/>}
          <Select label="status" placeholder='Choose Status' clearable style={{marginLeft: 10}}
            onChange={this.handleChange("status")} value={this.state.status} options={statusOptions} width={2}/>
        </div>
        <center>
          <table borderless border="1" width='100%'>
              <tr class="table-title">
                <th>ID</th>
                <th>Customer</th>
                <th>Title</th>
                <th>FollowUp</th>
                <th>Start Date</th>
                <th>Next follow Up</th>
                <th>History</th>
                <th>Status</th>
                <th>Created By</th>
                <th>Added On</th>
                <th>Edit</th>
              </tr>
            { data }
          </table>
        </center>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignFollowUpId: (id) => dispatch(assignFollowUpId(id)),
});

export default connect(null, mapDispatchToProps)(FollowUp);
