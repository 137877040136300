import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Segment, Header, Button, Image, Modal, Icon, Table, Form } from 'semantic-ui-react';

import Server from '../../function/server';
import Shop from '../../function/shop';

export default class CustomFieldFilter extends Component {

  state = { fieldValues: [], fields: [] }

  close = () => {
    this.setState({openDialog: false});
  }

  componentDidMount() {
    // this.customFilter();
    this.getValue();
  }

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  // customFilter = () => {
  //   let url = Server() + "field/getallbyshop.php";
  //   let data = { id: Shop().id, customer_id: 0 };
  //   $.ajax({url, method: "POST", data, success: (data) => {
  //     let Field = JSON.parse(data);
  //     this.setState({ Field });
  //   }, error: (e) => {
  //     console.log(e);
  //     alert("Error");
  //   }});
  // }

  getValue = () => {
    let url = Server() + "editotherfields/getbycustomerid.php";
    let data = { id: Shop().id };
    $.ajax({url, method: "POST", data, success: (data) => {
      data  = JSON.parse(data);
      let fields = [], fieldValues = [];
      data.map((d) => {
        if( d.type === "field" ) fields.push(d);
        else fieldValues.push(d);
      });
      this.setState({fields, fieldValues});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    const { openDialog, dimmer } = this.state;
    let fieldOptions = this.state.fields.map((f) => {
      return {key: f.id, value: f.id, text: f.field};
    })
    let valueOptions = [];
    for (var i = 0; i < this.state.fieldValues.length; i++) {
      let v = this.state.fieldValues[i];
      if (v.master == this.state.field) {
        valueOptions.push({key: v.id, value: v.id, text: v.field});
      }
    }
    return (
      <div style={{margin: 1}}>
        <Button primary onClick={() => this.setState({openDialog: true})}>
          Customer Field Filter
        </Button>
        <Modal dimmer={dimmer} open={openDialog} onClose={this.close}  style={{width:700, height:450, marginTop: 100, marginLeft:340}}>
          <Modal.Content>
            <Icon circular inverted color='orange' name='close' onClick={this.close} />
            <Header style={{color: 'red'}}></Header>
            <Modal.Description>
              <Form style={{marginTop: '10%', marginLeft:'35%'}}>
                <Form.Select label="Field" placeholder="Select Field" options={fieldOptions}  clearable
                   onChange={this.handleChange("field")} width={3}/>
                 <Form.Select label="Field Value" placeholder="Select Fieldvalue" clearable
                   onChange={this.handleChange("values")} options={valueOptions}  width={7}/>
                <Form.Button primary>Filter</Form.Button>
              </Form>
           </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}
