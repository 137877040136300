import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../../function/server';
import UserId from '../../../function/userid';

class AddContact extends Component {

  state = {}

  componentDidMount() {
    let { contactId, customerId } = this.props;
    if(!isNaN(contactId) && contactId > 0) {
      this.getContact(contactId);
    }
    if(!isNaN(customerId) && customerId > 0) {
      this.setState({ customerId });
    }
  }

  handleChange = (type) => (e) => {
    this.setState({[type]: e.target.value});
  }

  getContact = (id) => {
    let url = Server() + "customercontact/getbyid.php";
    $.ajax({ url, method: "POST", data: {id}, success: (data) => {
      let c = JSON.parse(data);
      let { id, name, mobile, alt_mobile, mail, address } = c;
      this.setState({ id, name, mobile, alt_mobile, mail, address, edit: true });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  add = () => {
    let { name, mobile, alt_mobile, mail, address, customerId } = this.state;
    if ( !name || name.length < 1 || !mobile || mobile.length < 1 || !mail || mail.length < 1 || !alt_mobile || alt_mobile.length < 1 ||
         !address || address.length < 1 ) {
      alert("Invalid Information"); return;
    }
    let data = { customer_id: customerId, name, mobile, alt_mobile, mail, address, created_by: UserId() };
    let url = Server() + "customercontact/add.php";
    if(this.state.edit) {
      data = { id: this.state.id, name, mobile, alt_mobile, mail, address };
      url = Server() + "customercontact/edit.php";
    }
    $.ajax({ url, method: "POST", data, success: (data) => {
      if ( data == "1" || data == "true" ) {
        alert(this.state.edit ? "Updated Successfully" : "Information Added Successfully");
        this.setState({ name: "", mobile: "", alt_mobile: "", mail: "", address: "" });
      } else {
        alert("Failed to Add");
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    return (
      <div style={{margin: 20}}>
        <center>
          <Form>
            <Form.Group style={{marginLeft: 50}}>
              <Form.Input fluid label='Name' placeholder='Enter Contact Name'
                value={this.state.name} onChange={this.handleChange("name")} width={5}/>
              <Form.Input fluid label='Mobile Number' placeholder='Enter Mobile Number'
                value={this.state.mobile} onChange={this.handleChange("mobile")} width={5}/>
              <Form.Input fluid label='Alternate' placeholder='Enter Alernate Number'
                value={this.state.alt_mobile} onChange={this.handleChange("alt_mobile")} width={5}/>
            </Form.Group>
            <Form.Group style={{marginLeft: 50}}>
              <Form.Input fluid label='E-Mail' placeholder='Enter Email Id'
                value={this.state.mail} onChange={this.handleChange("mail")} width={5}/>
              <Form.Input fluid label='Address' placeholder='Enter Address'
                value={this.state.address} onChange={this.handleChange("address")} width={5}/>
            </Form.Group>
            <center>
              <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
            </center>
          </Form>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contactId: state.contactId, customerId: state.customerId
});

export default connect(mapStateToProps, null)(AddContact);
