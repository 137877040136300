import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddSecondary extends Component {

  state = { primaryCategories: [] }

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  componentDidMount() {
    this.getPrimaryId();
    let { secondaryId } = this.props;
    if (!isNaN(secondaryId) && secondaryId > 0) {
      this.getSecondaryCategory(secondaryId);
    }
  }

  getSecondaryCategory = (id) => {
    let url = Server() + "secondarycategory/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, success: (data) => {
      let c = JSON.parse(data);
      let { name, id, primary_category_id, prefix_id } = c;
      this.setState({name, id, category: primary_category_id, prefix_id, edit: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  getPrimaryId = () => {
    let url = Server() + "primarycategory/getallbyshop.php";
    let data = {id: Shop().id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading"});
    }, success: (data) => {
      let primaryCategories = JSON.parse(data);
      this.setState({ primaryCategories, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  add = () => {
    let { name, category, prefix_id} = this.state;
    if(!name || name.length < 1 || category < 1 || !prefix_id || prefix_id.length < 1) {
      alert("Invalid Name or Category"); return;
    }
    let data = {name, created_by: UserId(), primary_category_id: category, prefix_id};
    let url = Server() + "secondarycategory/add.php";
    if (this.state.edit) {
      data = {id: this.state.id, name, primary_category_id: category, prefix_id};
      url = Server() + "secondarycategory/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding" });
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Secondary Category Added Successfully");
        this.setState({name: "", category: "", prefix_id: "", load: false});
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    let primaryCategoryOptions = this.state.primaryCategories.map((pc) => {
      return {key: pc.id, value: pc.id, text: pc.name};
    });
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form style={{marginLeft: '5%'}}>
          <center>
            <Form.Group>
              <Form.Select fluid label='Primary Category' placeholder='Primary Category' clearable
                onChange={this.handleChange("category")} value={this.state.category} options={primaryCategoryOptions} width={5}/>
              <Form.Input fluid label='Name' placeholder='Name' style={{textAlign: "left"}}
                value={this.state.name} onChange={this.handleChange("name")} width={5}/>
              <Form.Input fluid label='Prefix Id' placeholder='Enter Prefix Id'
                value={this.state.prefix_id} onChange={this.handleChange("prefix_id")} width={5}/>
            </Form.Group>
              <Form.Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Form.Button>
          </center>
       </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  secondaryId: state.secondaryId,
});

export default connect(mapStateToProps, null)(AddSecondary);
