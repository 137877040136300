import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddPrimary extends Component {

  state = {}

  componentDidMount() {
    let { primaryId } = this.props;
    if (!isNaN(primaryId) && primaryId > 0) {
      this.getPrimaryCategory(primaryId);
    }
  }

  getPrimaryCategory = (id) => {
    let url = Server() + "primarycategory/getbyid.php";
    $.ajax({url, method: "POST", data: { id }, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let { name, id, prefix_id } = c;
      this.setState({name, id, prefix_id, edit: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  handleChange = (type) => (e, { value }) => {
    this.setState({[type]: value});
  }

  add = () => {
    let {name, prefix_id} = this.state;
    if(!name || name.length < 1 || !prefix_id || prefix_id.length < 1 ) {
      alert("Invalid Name"); return;
    }
    let data = {shop_id: Shop().id, name, prefix_id, created_by: UserId()};
    let url = Server() + "primarycategory/add.php";
    if (this.state.edit) {
      data = {id: this.state.id, name, prefix_id};
      url = Server() + "primarycategory/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Add"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Primary Category Added Successfully");
        this.setState({name: "", prefix_id: "", load: false});
      } else {
        alert("Failed"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <center>
            <Form.Input fluid label='Name' placeholder='Enter Name'
              value={this.state.name} onChange={this.handleChange("name")} width={3}/>
            <Form.Input fluid label='Prefix Id' placeholder='Enter Prefix Id'
              value={this.state.prefix_id} onChange={this.handleChange("prefix_id")} width={3}/>
            <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  primaryId: state.primaryId,
});

export default connect(mapStateToProps, null)(AddPrimary);
