import UserId from './userid';

function Shop() {
  try {
    let userId = UserId();
    let shop = JSON.parse(window.localStorage.getItem("shop"));
    return {id: shop[userId]};
  } catch (e) {
    return {id: "0"};
  }
}

export default Shop;
