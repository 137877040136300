import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Checkbox, Select, Radio, Icon, Input } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import { assignWorkStatusId, assignCustomerId } from '../redux/actions';

import Server from '../function/server';
import UserId from '../function/userid';
import Shop from '../function/shop';
import Load from '../util/load';
import Details from './details';

class WorkStatus extends Component {

  state = { data: [], status: -1, sort: "", sortType: "", status: "0", search: "" }

  componentDidMount() {
    this.getWorkStatus();
  }

  handleChange = (type) => (e, data, value) => {
    if (type === "viewAll") { this.setState({[type]: data.checked}, this.getWorkStatus); return; }
    this.setState({[type]: data.value}, () => {
      if( type !== "search") {
        this.getWorkStatus();
      }
    });
  }

  getWorkStatus = () => {
    let url = Server() + "workstatus/getallbycustomer.php";
    let data = { sort: "", id: UserId(), shop_id: Shop().id };
    if (this.state.status >= 0 && !isNaN(this.state.status)) data["status"] = this.state.status;
    if (this.state.viewAll) { data["view_all"] = "1"; }
    if (this.state.sort.length > 0) data["sort"] = "ORDER BY " + this.state.sort + " " + this.state.sortType;
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading WorkStatus"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      this.setState({data});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

   render() {
     let { assignWorkStatusId, assignCustomerId } = this.props;
     let controls = window.localStorage.getItem("controls");
     controls = (!controls || controls.length < 1) ? "" : controls.toLowerCase();
     let statusOptions = [{key: 0, value: "0", text: "Open"}, {key: 1, text: "Processing", value: "1"},
       {key: 2, text: "Closed", value: "2"}];
     let sortOptions = [{key: 0, text: "Start Date", value: "start_date"},
        {key: 1, text: "Expected Close Date", value: "expected_close_date"},
        {key:2, text: "Restart Date", value: "restart_date"}];
     let { search } = this.state;
     search = search.trim().toLowerCase();
     let data = this.state.data.map((w) => {
       let status = (w.status === "0") ? "Open" : (w.status === "1") ? "Processing" : "Closed";
       let received = (w.received === "0") ? "Not Receiving" : (w.received === "1") ? "Partially Received" : "Fully Received";
       if(!(w.name.toLowerCase().includes(search) || w.type.toLowerCase().includes(search))) {
         return "";
       }
       return(
           <tr>
             <td>{w.id}</td>
             <td><Details customer_id={w.customer_id} name={w.name}/></td>
             <td>{w.type}</td>
             <td>{w.title}</td>
             <td>{w.work_pending}</td>
             <td>{w.allocated_to}</td>
             <td>{w.start_date.split(" ")[0]}</td>
             <td>{w.expected_close_date.split(" ")[0]}</td>
             <td><Link to="/Work-status/history" onClick={() => assignWorkStatusId(w.id)}>{w.history}</Link></td>
             <td>{w.restart_date.split(" ")[0]}</td>
             <td>{received}</td>
             <td>{w.created_by}</td>
             <td>{status}</td>
             <td>{w.timestamp}</td>
             <td><Link to="/work-status/edit" onClick={() => assignWorkStatusId(w.id)}>
              <Icon name='edit' />
             </Link></td>
           </tr>
       )
     })
     return(
       <div style={{margin: 20}}>
         <Load load={this.state.load} message={this.state.message}/>
         <div style={{margin: 10, float: "left"}}>
           <Input icon='search' placeholder="Search" onChange={this.handleChange("search")} />
         </div>
         <div style={{margin: 10, float: "right"}}>
           {controls.includes("admin") && <Checkbox label="View All" style={{marginRight: 10}} checked={this.state.viewAll}
             onChange={this.handleChange("viewAll")}/>}
           <Select label="Status" placeholder='Choose Status' clearable style={{marginLeft: 10}}
             onChange={this.handleChange("status")} value={this.state.status} options={statusOptions} width={2}/>
         </div>
         <div style={{margin: 10, float: "left"}}>
           <Select label="Sort By" placeholder='Sort By' clearable style={{marginLeft: 10}}
             onChange={this.handleChange("sort")} value={this.state.sort} options={sortOptions} width={2}/>
         </div>
         <div style={{margin: 10, float: "left"}}>
           <Radio label='Recent First' name='radioGroup' value='DESC'
             checked={this.state.sortType === "DESC"} onChange={this.handleChange("sortType")}/>
           <Radio label='Oldest First' name='radioGroup' value='ASC' style={{marginLeft:10, marginTop: 10}}
             checked={this.state.sortType === "ASC"} onChange={this.handleChange("sortType")}/>
         </div>
         <table borderless border="1" width='100%'>
             <tr class="table-title">
               <th>ID</th>
               <th>Customer</th>
               <th>Work Type</th>
               <th>Title</th>
               <th>Work Pending</th>
               <th>Allocated</th>
               <th>Start Date</th>
               <th>Expected Close date</th>
               <th>History</th>
               <th>Restart date</th>
               <th>Document Received</th>
               <th>Created By</th>
               <th>Status</th>
               <th>Added On</th>
               <th>Edit</th>
             </tr>
           { data }
         </table>
       </div>
     )
   }
}

const mapDispatchToProps = (dispatch) => ({
  assignWorkStatusId: (id) => dispatch(assignWorkStatusId(id)),
  assignCustomerId: (id) => dispatch(assignCustomerId(id)),
});

export default connect(null, mapDispatchToProps)(WorkStatus);
