export const ASSIGN_CUSTOMER_ID = "ASSIGN_CUSTOMER_ID";
export const ASSIGN_BANK_ID = "ASSIGN_BANK_ID";
export const ASSIGN_PRIMARY_ID = "ASSIGN_PRIMARY_ID";
export const ASSIGN_SECONDARY_ID = "ASSIGN_SECONDARY_ID";
export const ASSIGN_ENQUIRY_ID = "ASSIGN_ENQUIRY_ID";
export const ASSIGN_WORKTYPE_ID = "ASSIGN_WORKTYPE_ID";
export const ASSIGN_FOLLOWUP_ID = "ASSIGN_FOLLOWUP_ID";
export const ASSIGN_FOLLOWHISTORY_ID = "ASSIGN_FOLLOWHISTORY_ID";
export const ASSIGN_WORKSTATUS_ID = "ASSIGN_WORKSTATUS_ID";
export const ASSIGN_WORKHISTORY_ID = "ASSIGN_WORKHISTORY_ID";
export const ASSIGN_CONTROL_ID = "ASSIGN_CONTROL_ID";
export const ASSIGN_GROUP_ID = "ASSIGN_GROUP_ID";
export const ASSIGN_FIELD_ID = "ASSIGN_FIELD_ID";
export const ASSIGN_CONTACT_ID = "ASSIGN_CONTACT_ID";
export const ASSIGN_ALTERNATE_ID = "ASSIGN_ALTERNATE_ID";
