import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddWork extends Component {

  state = {}

  handleChange = (type) => (e, {value}) => {
    if(type == 'period') {
      let values = "";
      if(value == "Weekly"){
        let values = 7;
        let start_date = this.setDate(new Date(), 0);
        let restart_date = this.setDate(new Date(), values);
        this.setState({ start_date, restart_date });
      }
      else if(value == "Monthly"){
        let values = 30;
        let start_date = this.setDate(new Date(), 0);
        let restart_date = this.setDate(new Date(), values);
        this.setState({ start_date, restart_date });
      }
      else if(value == "Quarterly"){
        let values = 90;
        let start_date = this.setDate(new Date(), 0);
        let restart_date = this.setDate(new Date(), values);
        this.setState({ start_date, restart_date });
      }
      else if(value == "Halfly"){
        let values = 180;
        let start_date = this.setDate(new Date(), 0);
        let restart_date = this.setDate(new Date(), values);
        this.setState({ start_date, restart_date });
      }
      else if(value == "Yearly"){
        let values = 365;
        let start_date = this.setDate(new Date(), 0);
        let restart_date = this.setDate(new Date(), values);
        this.setState({ start_date, restart_date });
      }
      else if (value == "") {
        let start_date = "";
        let restart_date = "";
        this.setState({ start_date, restart_date });
      }
    }
    this.setState({[type]: value});
  }

  setDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() + (interval * 24 * 60 * 60 * 1000));
    }
    let month = dates.getMonth() + 1; let date = dates.getDate();
    month = (month >= 10) ? month : "0" + month;
    date = (date >= 10) ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    let { workTypeId } = this.props;
    if (!isNaN(workTypeId) && workTypeId > 0) {
      this.getWorkType(workTypeId);
    }
  }

  getWorkType = (id) => {
    let url = Server() + "worktype/getbyid.php";
    $.ajax({url, method: "POST", data: { id }, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let { type, id, period } = c;
      this.setState({type, id, period, edit: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  add = () => {
    let {type, period} = this.state;
    if(!type || type.length < 1 || !period || period.length < 1) {
      alert("Invalid Information"); return;
    }
    let data = {shop_id: Shop().id, type, period, created_by: UserId()};
    let url = Server() + 'worktype/add.php';
    if (this.state.edit) {
      data = {id: this.state.id, type, period};
      url = Server() + "worktype/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "Work Type Added Successfully");
        this.setState({type: "", period: "", load: false});
      } else {
        alert("Failed to Add");
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    let periodOptions = [{key: 0, value: "Weekly", text: "Weekly"},
     {key: 1, value: "Monthly", text: "Monthly"}, {key: 2, value: "Quarterly", text: "Quarterly"},
     {key: 3, value: "Halfly", text: "Halfly"}, {key: 4, value: "Yearly", text: "Yearly"}];
    return(
      <div style={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <center>
            <Form.Input fluid label='Name' placeholder='Enter Work Type'
              value={this.state.type} onChange={this.handleChange("type")} width={3}/>
            <Form.Select label="Period" placeholder="Choose Period" value={this.state.period} clearable
              onChange={this.handleChange("period")} options={periodOptions} width={3} />
            <Form.Group style={{display: 'none'}}>
                <Form.Input label='Starting Date' placeholder='Date' type="date"
                  value={this.state.start_date} onChange={this.handleChange("start_date")} width={3}/>
                <Form.Input label='Restart Date' placeholder='Date' clearable type="date"
                  value={this.state.restart_date} onChange={this.handleChange("restart_date")} width={3}/>
            </Form.Group>
            <Button primary onClick={this.add}>{this.state.edit ? "Edit" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  workTypeId: state.workTypeId,
});

export default connect(mapStateToProps, null)(AddWork);
