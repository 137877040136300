import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'
import 'mdbreact/dist/css/mdb.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import $ from 'jquery';
import {Form} from 'semantic-ui-react';

import './App.css';
import Server from './function/server';

import NavbarPage from './util/title';
import Home from './home';
import Customers from './master/customer';
import PrimaryCategory from './master/primarycategory';
import SecondaryCategory from './master/secondarycategory';
import EnquiryType from './master/enquirytype';
import AddPrimary from './master/primarycategory/add';
import AddEnquiry from './master/enquirytype/add';
import AddSecondary from './master/secondarycategory/add';
import AddCustomer from './master/customer/add';
import BankInfo from './master/bank';
import AddBank from './master/bank/add';
import AddFollowUp from './followup/add';
import FollowUp from './followup';
import CustomerFollow from './followup/customerfollow';
import History from './followup/history';
import AddHistory from './followup/history/add';
import WorkStatus from './workstatus';
import WorkType from './master/worktype';
import AddWork from './master/worktype/add';
import AddWorkStatus from './workstatus/add';
import CustomerDetails from './workstatus/details';
import WorkHistory from './workstatus/history';
import AddWorkHistory from './workstatus/history/add';
import Control from './control';
import AddGroup from './master/group/add';
import AddControl from './control/add';
import Group from './master/group';
import AddField from './master/fields/add';
import Field from './master/fields';
import Contact from './master/customer/contact';
import AddContact from './master/customer/contact/add';
import AddCustomerField from './master/customer/editfields';
import CustomerAllDetails from './master/customer/alldetails';
import Alternate from './master/alternatecategory';
import AddAlternate from './master/alternatecategory/add';
import UserId from './function/userid';
import Shop from './function/shop';
import Load from './util/load';

export default class App extends React.Component {

  state = { data: [] }

  componentDidMount() {
    if (!UserId() || UserId().length < 1) {
      window.location.href = "https://you.strackit.com/?redirectto=" + window.location.href; return;
    }
    this.getControl();
    this.setState({controls: window.localStorage.getItem("controls")});
  }

  handleChange = (type) => (e) => {
    this.setState({[type]: e.target.value});
  }

  getControl = () => {
    let url = Server() + "control/getbyid.php";
    let data = { user_id: UserId(), own: 'true' };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Controls"});
    }, success: (data) => {
      this.setState({load: false})
      try {
        if (data === "0") throw null;
        let dataObj = JSON.parse(data);
        data = JSON.stringify(dataObj.control);
        window.localStorage.setItem("controls", data);
        window.localStorage.setItem("shop", JSON.stringify({[UserId()]: dataObj.shop_id}));
        this.setState({controls: data});
      } catch (e) {
        if (isNaN(Shop().id) || Shop().id < 1 || !window.localStorage.getItem("controls")
          || window.localStorage.getItem("controls").length < 5) {
          this.getShop();
        }
      }
    }, error: (e) => {
      this.setState({load: false});
      alert("Error");
    }});
  }

  getShop = () => {
    let url = "https://business.strackit.com/business/shop/getshopbyid.php?id=" + UserId();
    $.ajax({url, method: "GET", beforeSend: () => {
      this.setState({load: true, message: "Loading Shop"});
    }, success: (data) => {
      this.setState({load: false});
      try {
        if (data === "0") throw null;
        let dataObj = JSON.parse(data);
        window.localStorage.setItem("shop", JSON.stringify({[UserId()]: dataObj.id}));
        window.localStorage.setItem("controls", "Admin");
        this.setState({controls: "Admin"});
      } catch (e) {
        window.localStorage.setItem("controls", "none");
        this.setState({controls: "none"});
        window.localStorage.setItem("shop", JSON.stringify({[UserId()]: "0"}));
      }
    }, error: (e) => {
      this.setState({load: false});
      console.log(e);
      alert("Error");
    }})
  }

  getRoutes = () => {
    let routes = [], { controls } = this.state;
    controls = (!controls || controls.length < 1) ? "" : controls.toLowerCase();
    let master = [
      (<Route exact path="/customers/active"><Customers status={1} /></Route>),
      (<Route exact path="/customers/inactive"><Customers status={0} /></Route>),
      (<Route exact path="/primary-categories/"><PrimaryCategory /></Route>),
      (<Route exact path="/secondary-categories"><SecondaryCategory /></Route>),
      (<Route exact path="/enquiry-types"><EnquiryType /></Route>),
      (<Route exact path="/customers/add"><AddCustomer /></Route>),
      (<Route exact path="/customers/edit"><AddCustomer /></Route>),
      (<Route exact path="/secondary-categories/add"><AddSecondary /></Route>),
      (<Route exact path="/secondary-categories/edit"><AddSecondary /></Route>),
      (<Route exact path="/enquiry-types/add"><AddEnquiry /></Route>),
      (<Route exact path="/enquiry-types/edit"><AddEnquiry /></Route>),
      (<Route exact path="/primary-categories/add"><AddPrimary /></Route>),
      (<Route exact path="/primary-categories/edit"><AddPrimary /></Route>),
      (<Route exact path="/bank-info"><BankInfo /></Route>),
      (<Route exact path="/bank-info/add"><AddBank /></Route>),
      (<Route exact path="/bank-info/edit"><AddBank /></Route>),
      (<Route exact path="/work-type"><WorkType /></Route>),
      (<Route exact path="/work-type/add"><AddWork /></Route>),
      (<Route exact path="/work-type/edit"><AddWork /></Route>),
      (<Route exact path="/group"><Group /></Route>),
      (<Route exact path="/group/add"><AddGroup /></Route>),
      (<Route exact path="/group/edit"><AddGroup /></Route>),
      (<Route exact path="/field"><Field /></Route>),
      (<Route exact path="/field/add"><AddField /></Route>),
      (<Route exact path="/field/edit"><AddField /></Route>),
      (<Route exact path="/customer/editfields"><AddCustomerField /></Route>),
      (<Route exact path="/customer/alldetails"><CustomerAllDetails /></Route>),
      (<Route exact path="/customer/contact"><Contact /></Route>),
      (<Route exact path="/customer/contact/add"><AddContact /></Route>),
      (<Route exact path="/customer/contact/edit"><AddContact /></Route>),
      (<Route exact path="/alternate-categories"><Alternate /></Route>),
      (<Route exact path="/alternate-categories/add"><AddAlternate /></Route>),
      (<Route exact path="/alternate-categories/edit"><AddAlternate /></Route>)
  ];
  let followup = [
    (<Route exact path="/walkin-enquiry"><FollowUp status= {0}/></Route>),
    (<Route exact path="/customer-enquiry"><FollowUp status= {1}/></Route>),
    (<Route exact path="/walkin-enquiry/add"><AddFollowUp walkin={true} status={0}/></Route>),
    (<Route exact path="/customer-enquiry/add"><AddFollowUp walkin={false} status={1}/></Route>),
    (<Route exact path="/enquiry/edit"><AddFollowUp /></Route>),
    (<Route exact path="/enquiry/history"><History /></Route>),
    (<Route exact path="/enquiry/history/add"><AddHistory /></Route>),
    (<Route exact path="/enquiry/history/edit"><AddHistory /></Route>)
  ];
  let workstatus = [(
    <Route exact path="/work-status"><WorkStatus /></Route>),
    (<Route exact path="/work-status/add"><AddWorkStatus /></Route>),
    (<Route exact path="/work-status/edit"><AddWorkStatus /></Route>),
    (<Route exact path="/Work-status/history"><WorkHistory /></Route>),
    (<Route exact path="/Work-status/history/add"><AddWorkHistory /></Route>),
    (<Route exact path="/Work-status/history/edit"><AddWorkHistory /></Route>),
    (<Route exact path="/work-status/details"><CustomerDetails /></Route>)
  ];
  let control = [(<Route exact path="/control"><Control /></Route>),
    (<Route exact path="/control/add"><AddControl /></Route>),
    (<Route exact path="/control/edit"><AddControl /></Route>)];
  routes.push(<Route exact path="/"><Home /></Route>);
  if (controls.includes("admin")) {
    routes.push(master); routes.push(followup); routes.push(workstatus); routes.push(control);
  } else {
    let out;
    out = (controls.includes("master")) ? routes.push(master) : routes;
    out = (controls.includes("follow up")) ? routes.push(followup) : routes;
    out = (controls.includes("work status")) ? routes.push(workstatus) : routes;
    out = (controls.includes("controls")) ? routes.push(control) : routes;
  }
  return routes;
  }

  render() {
    if ((isNaN(Shop().id) || Shop().id < 1) && !this.state.load) {
      return (
        <center style={{marginTop: 50}}>
          <Load load={this.state.load} message={this.state.message}/>
          Unknown Business
        </center>
      );
    }
    let routes = this.getRoutes();
    return (
      <div>
        <Load load={this.state.load} message={this.state.message}/>
        <Router>
          <NavbarPage controls={this.state.controls}/>
          <Switch>{this.getRoutes()}</Switch>
        </Router>
      </div>
    );
  }
}
