import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddGroup extends Component {

  state = {}

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  componentDidMount() {
    let { groupId } = this.props;
    if (!isNaN(groupId) && groupId > 0) {
      this.getGroup(groupId);
    }
  }

  getGroup = (id) => {
    let url = Server() + "group/getbyid.php";
    $.ajax({ url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({ load: true, message: "Loading"})
    }, success: (data) => {
      let c = JSON.parse(data);
      let { group_name, id } = c;
      this.setState({ group_name, id, edit: true, load: false})
    }, error: (e) => {
      console.log(e);
      alert("Error")
    }})
  }

  add = () => {
    let { group_name } = this.state;
    if ( !group_name || group_name.length < 1 ) {
      alert("Invalid Information"); return;
    }
    let data = [];
    let url;
    if (this.state.edit) {
      data = { id: this.state.id, group_name };
      url = Server() + "group/edit.php";
    }
    else {
      data = {shop_id: Shop().id, group_name, created_by: UserId()};
      url = Server() + "group/add.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Adding Data"});
    }, success: (data) => {
      if ( data == "1" || data == "true" ) {
        alert(this.state.edit ? "Updated Successfully" : "Group Added Successfully");
        this.setState({ group_name: "", load: false });
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    return (
      <div style={{margin: 30}}>
        <Load load={this.state.load} message={this.state.message} />
        <Form>
          <center>
            <Form.Input label="Group Name" placeholder="Enter Group Name" value={this.state.group_name}
              onChange={this.handleChange("group_name")} width={3}/>
            <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  groupId: state.groupId,
});

export default connect(mapStateToProps, null)(AddGroup);
