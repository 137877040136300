import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import $ from 'jquery';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';

class AddHistory extends Component {

  state = { title: [] }

  componentDidMount() {
    let { followHistoryId, followUpId } = this.props;
    if(!isNaN(followHistoryId) && followHistoryId > 0) {
      this.getHistory(followHistoryId);
    }
    if(!isNaN(followUpId) && followUpId > 0) {
      this.setState({ followUpId });
    }
  }

  handleChange = (type) => (e, {value}) => {
    this.setState({[type]: value});
  }

  getHistory = (id) => {
    let url = Server() + "followuphistory/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({load: true, message: "Loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {id, followup_id, content, followup_time, next_followup} = c;
      followup_time = followup_time.split(" ")[0];
      next_followup = next_followup.split(" ")[0];
      this.setState({id, followUpId: followup_id, content, followup_time, next_followup, edit: true});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  add = () => {
    let {followUpId, content, followup_time, next_followup} = this.state;
    if (!content || content.length < 1 || !followup_time || followup_time.length < 1 ||
          !next_followup || next_followup.length < 1) {
            alert("Invalid Information"); return;
    }
    let data = {followup_id: followUpId, content, followup_time, next_followup, created_by: UserId()};
    let url = Server() + "followuphistory/add.php";
    if (this.state.edit) {
      data = {id: this.state.id, content, followup_time, next_followup};
      url = Server() + "followuphistory/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Adding Data"});
    }, success: (data) => {
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated successfully" : "Enquiry History Added Successfully");
        this.setState({content: "", followup_time: "", next_followup: "", load: false});
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <Form.Input fluid label='Content' placeholder='Enter content'
            value={this.state.content} onChange={this.handleChange("content")} width={6}/>
          <Form.Group>
            <Form.Input fluid label='Followup Time' placeholder='Enter Followup Date' type="date"
              value={this.state.followup_time} onChange={this.handleChange("followup_time")} width={4}/>
            <Form.Input fluid label='Next FollowUp' placeholder='Enter Next Followup Date' type="date"
              value={this.state.next_followup} onChange={this.handleChange("next_followup")} width={4}/>
          </Form.Group>
          <center>
            <Button color= "blue" primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
          </center>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  followHistoryId: state.followHistoryId, followUpId: state.followUpId
});

export default connect(mapStateToProps, null)(AddHistory);
