import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Select, Icon, Search, Dropdown, Input, Checkbox } from 'semantic-ui-react';
import $ from 'jquery';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import UserId from '../../function/userid';
import Shop from '../../function/shop';
import Load from '../../util/load';
import CustomFieldFilter from './customfieldfilter';

import { assignCustomerId } from '../../redux/actions';

class Customers extends Component {

  state = { data: [], primaryCategories: [], secondaryCategories: [], enquiryTypes:[], status: -1, search: "", groups: [] }

  componentDidMount() {
    this.getAddCustomerInfo();
    this.getCustomers(0);
    this.getGroup();
  }

  componentDidUpdate(props) {
    if (props.status != this.props.status) {
      this.getCustomers(0);
    }
  }

  handleChange = (type) => (e, {value}) => {
    console.log(e, value);
    this.setState({[type]: value}, () => {
      if (type !== "search") {
        this.getCustomers(value);
      }
    });
  }

  getAddCustomerInfo = () => {
    let url = Server() + "customer/getaddcustomerinfobyshop.php";
    let data = {id: Shop().id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Customer Info"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      let primaryCategories = [], secondaryCategories = [], enquiryTypes = [];
      data.map((d) => {
        if (d.type === "primary") primaryCategories.push(d);
        else if (d.type === "secondary") secondaryCategories.push(d);
        else enquiryTypes.push(d);
      });
      this.setState({ primaryCategories, secondaryCategories, enquiryTypes });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  alternativeCategory(value) {
    let primaryCategories = this.state.primaryCategories;
    let array = [];
    let categoryName = '';
    try {
      array = value.split('');
       array.map((b) => {
       primaryCategories.map((v) => {
         if (v.id == b) {
             categoryName = categoryName.concat(v.name+ " ");
         }
       })
     })
     return categoryName;
    } catch (e) {
    }
    return categoryName;
  }

  getGroup = () => {
    let url = Server() + "group/getallbyshop.php";
    let data = {id: Shop().id};
    $.ajax({ url, method: "POST", data, success: (data) => {
      let groups = JSON.parse(data);
      this.setState({ groups });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getCustomers = (value) => {
    let url = Server() + "customer/getallbyshopandusercategory.php";
    let category = window.localStorage.getItem("controls");
    let data = {shop_id: Shop().id, id: UserId(), category, value, status: this.props.status};
    if (this.state.primaryCategory > 0) data["alternativeCategory"] = this.state.primaryCategory;
    if (this.state.secondaryCategory > 0) data["secondary_category"] = this.state.secondaryCategory;
    if (this.state.enquiryType > 0) data["enquiry_type"] = this.state.enquiryType;
    if (this.state.groupId > 0) data["group_id"] = this.state.groupId;
    $.ajax({url, method : 'POST', data, success: (data) => {
      data = JSON.parse(data);
      this.setState({data});
    }, error: (err) => {
      console.log(err);
      alert(err);
    }});
  }

  render() {
    let primaryCategoryOptions = this.state.primaryCategories.map((pc) => {
      return {key: pc.id, value: pc.id, text: pc.name};
    });
    let secondaryCategoryOptions = [];
    for (var i = 0; i < this.state.secondaryCategories.length; i++) {
      let sc = this.state.secondaryCategories[i];
      if (sc.master == this.state.primaryCategory) {
        secondaryCategoryOptions.push({key: sc.id, value: sc.id, text: sc.name});
      }
    }
    let enquiryTypeOptions = this.state.enquiryTypes.map((et) => {
      return {key: et.id, value: et.id, text: et.name};
    });
    let groupOptions = this.state.groups.map((g) => {
      return {key: g.id, value: g.id, text: g.group_name};
    });
    let { assignCustomerId } = this.props;
    let { search } = this.state;
    search = search.trim().toLowerCase();
    return (
      <div style={{margin: 10, marginBottom: '3%'}}>
        <Load load={this.state.load} message={this.state.message}/>
        <div style={{float: "Left", margin:10, marginBottom: 25}}>
          <Input iconPosition='left' icon='search' placeholder='Search'
            onChange={this.handleChange("search")} />
        </div>
        <div style={{float: "right", margin: 20}}>
          <Select label="Group" placeholder='Choose Group' clearable compact
            onChange={this.handleChange("groupId")} options={groupOptions} width={5} />
          <Select label='Primary Category' placeholder='Primary Category' clearable style={{marginLeft: 10}} compact
            onChange={this.handleChange("primaryCategory")} options={primaryCategoryOptions} width={5}/>
          <Select label='Secondary Category' placeholder='Secondary Category' clearable style={{marginLeft: 10}} compact
            onChange={this.handleChange("secondaryCategory")} options={secondaryCategoryOptions} width={5}/>
          <Select label='Enquiry Type' placeholder='Enquiry Type' clearable style={{marginLeft: 10}} compact
            onChange={this.handleChange("enquiryType")} options={enquiryTypeOptions} width={5}/>
          <Link to="/customers/add" style={{marginLeft: 10}} onClick={() => assignCustomerId(0)}>
            <Button icon labelPosition='left' primary><Icon name='user' />Add Customer</Button>
          </Link>
          <div style={{float: 'Right', display: 'none'}}>
           <CustomFieldFilter />
          </div>
        </div>
        <center>
          <table borderless border="1" width='100%'>
              <tr class="table-title">
                <th>Id</th>
                <th height="35">Name</th>
                <th>Moblie</th>
                <th>Mail</th>
                <th>Primary Category</th>
                <th>Secondary Category</th>
                <th>Enquiry Type</th>
                <th>Group</th>
                <th>Alternate</th>
                <th>GSTIN</th>
                <th>Pan</th>
                <th>Added On</th>
                <th>View</th>
                <th>Contact Details</th>
                <th>Bank Info</th>
                <th>Edit</th>
              </tr>
            {
              this.state.data.map((c) => {
                let status = (c.status === "0") ? "InActive" : "Active";
                if (!(c.name.toLowerCase().includes(search) || c.mobile.toLowerCase().includes(search) || c.mail.toLowerCase().includes(search))) {
                  return "";
                }
                return (
                    <tr>
                      <td width="50">{c.prefix_id}-{c.primary_custom_id}</td>
                      <td height="45">{c.name}</td>
                      <td>{c.mobile}</td>
                      <td>{c.mail}</td>
                      <td>{c.primary_category}</td>
                      <td>{c.secondary_category}</td>
                      <td>{c.enquiry_type}</td>
                      <td>{c.group_id}</td>
                      <td>{this.alternativeCategory(c.alternativeCategory)}</td>
                      <td>{c.gstin}</td>
                      <td>{c.pan}</td>
                      <td>{c.timestamp}</td>
                      <td><Link to="/customer/alldetails" onClick={() => assignCustomerId(c.id)}>
                        <Button inverted primary icon='eye' />
                      </Link>
                      </td>
                      <td><Link to="/customer/contact" onClick={() => assignCustomerId(c.id)}>
                        <Button inverted primary icon='phone' />
                      </Link>
                      </td>
                      <td color='primary'><Link to="/bank-info" onClick={() => assignCustomerId(c.id)} >
                        Bank Details
                      </Link></td>
                      <td>
                        <Link to={"/customers/edit"} onClick={() => assignCustomerId(c.id)}>
                          <Icon name='edit' />
                        </Link>
                      </td>
                    </tr>
                )
              })
            }
          </table>
        </center>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignCustomerId: (id) => dispatch(assignCustomerId(id)),
});

export default connect(null, mapDispatchToProps)(Customers);
