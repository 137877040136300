import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../../function/server';
import { assignContactId } from '../../../redux/actions';

class Contact extends Component {

  state = { data: [] }

  componentDidMount() {
    let id = this.props.customerId;
    if(!isNaN(id)) {
      this.setState({ id }, this.getContact)
    }
  }

  getContact = () => {
    let url = Server() + "customercontact/getcustomercontact.php";
    let data = { id: this.state.id }
    $.ajax({ url, method: "POST", data, success: (data) => {
      data = JSON.parse(data);
      this.setState({ data });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
  let { assignContactId } = this.props;
    let data = this.state.data.map((c) => {
      return (
        <tr>
          <td>{c.id}</td>
          <td>{c.customer_id}</td>
          <td>{c.name}</td>
          <td>{c.mobile}</td>
          <td>{c.alt_mobile}</td>
          <td>{c.mail}</td>
          <td>{c.address}</td>
          <td>{c.created_by}</td>
          <td>{c.timestamp}</td>
          <td><Link to ="/customer/contact/edit" onClick={() => assignContactId(c.id)}>
            <Icon style={{color: '#2185d0'}} name='edit' />
          </Link></td>
        </tr>
      )
    })
    return (
      <div style={{margin: 20}}>
        <Link to ="/customer/contact/add" style={{float: 'right', marginBottom: 10}} onClick={() => assignContactId(0)}>
          <Button icon labelPosition='left' primary><Icon name='phone' />Add Contact</Button>
        </Link>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>Id</th>
                <th>Customer Id</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Alternate Mobile</th>
                <th>Mail</th>
                <th>Address</th>
                <th>Created By</th>
                <th>Added On</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  customerId: state.customerId,
});

const mapDispatchToProps = (dispatch) => ({
  assignContactId: (id) => dispatch(assignContactId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
