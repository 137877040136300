import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';
import { Icon, Button } from 'semantic-ui-react';

import { assignControlId } from '../redux/actions';

import Server from '../function/server';
import Shop from '../function/shop';
import Load from '../util/load';

class Control extends Component {

  state = { data: []}

  componentDidMount() {
    this.getControl();
  }

  getControl = () => {
    let url = Server() + "control/getallbycontrol.php";
    let data = {id: Shop().id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Controls"});
    }, success: (data) => {
      data = JSON.parse(data);
      this.setState({data, load: false});
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  delete(user_id){
    let url = Server() + "control/delete.php";
    let data = {user_id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({ load: true, message: "Delete Data"});
    }, success: (data) => {
      if (data == "1" || data == "true") {
        alert("Delete Successfully");
        this.getControl();
        this.setState({load: false});
      } else {
        alert("Failed to delete"); console.log(data);
        this.setState({load: false});
      }
    }, error: (e) => {
      console.log(e);
      alert(data);
    }})
  }

  render() {
    let { assignControlId } = this.props;
    let data = this.state.data.map((c) => {
      return(
        <MDBTableBody>
          <tr>
            <td>{c.user_name}</td>
            <td>{c.shop_id}</td>
            <td>{c.control}</td>
            <td>{c.created_by}</td>
            <td>{c.timestamp}</td>
            <td><Link to="/control/edit" onClick={() => assignControlId(c.user_id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></td>
          <td><Button color= "blue" primary onClick={this.delete.bind(this, c.user_id)}>Delete</Button></td>
          </tr>
        </MDBTableBody>
      )
    });
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>User</th>
                <th>Shop ID</th>
                <th>Controls</th>
                <th>Created By</th>
                <th>Added On</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignControlId: (user_id) => dispatch(assignControlId(user_id)),
});

export default connect(null, mapDispatchToProps)(Control);
