import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

export default class Load extends React.Component {

  render() {
    let {load, message} = this.props;
    return (
      <Dimmer active={load} page>
        <Loader size='medium'>{message}</Loader>
      </Dimmer>
    );
  }

}
