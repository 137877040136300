import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import { assignBankId } from '../../redux/actions';
import Server from '../../function/server';
import Load from '../../util/load';

class BankInfo extends Component {

  state = { data: [] }

  componentDidMount() {
    let id = this.props.customerId;
    if(!isNaN(id)) {
      this.setState({ id }, this.getCustomerInfo)
    }
  }

  getCustomerInfo = () => {
    let url = Server() + "bankinfo/getallbycustomerid.php";
    let data = { id: this.state.id };
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading Bank Details"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      this.setState({data});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  render() {
  let { assignBankId } = this.props;
  let data = this.state.data.map((i) => {
    return (
      <MDBTableBody>
        <tr>
          <td>{i.id}</td>
          <td>{i.customer_id}</td>
          <td>{i.bank_name}</td>
          <td>{i.account_type}</td>
          <td>{i.account_number}</td>
          <td>{i.payee_name}</td>
          <td>{i.ifsc_code}</td>
          <td>{i.upi}</td>
          <td>{i.created_by}</td>
          <td>{i.timestamp}</td>
          <td><Link to ="/bank-info/edit" onClick={() => assignBankId(i.id)}>
            <Icon style={{color: '#2185d0'}} name='edit' />
          </Link></td>
        </tr>
      </MDBTableBody>
    )
  });
    return (
      <div style ={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to ="/bank-info/add" style={{float: 'right', marginBottom: 10}} onClick={() => assignBankId(0)}>
          <Button icon labelPosition='left' primary><Icon name='info' />Add Bank Information</Button>
        </Link>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>Id</th>
                <th>Customer Id</th>
                <th>Bank Name</th>
                <th>Account Type</th>
                <th>Account Number</th>
                <th>Payee Name</th>
                <th>IFSC Code</th>
                <th>UPI</th>
                <th>Created By</th>
                <th>Added On</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  customerId: state.customerId,
});

const mapDispatchToProps = (dispatch) => ({
  assignBankId: (id) => dispatch(assignBankId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankInfo);
