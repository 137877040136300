import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Form, Input } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import Load from '../../util/load';

class CustomerAllDetails extends Component {

  state = {data: []}

  componentDidMount() {
    let { customerId } = this.props;
    if (!isNaN(customerId) && customerId > 0) {
      this.getAllCustomer(customerId);
    }
    this.getFields();
  }

  getAllCustomer = (id) => {
    let url = Server() + "customer/getbyid.php";
    $.ajax({ url, method: "POST", data: { id }, beforeSend: () => {
      this.setState({ load: true, message: "Loading Datas"});
    }, success: (data) => {
      let c = JSON.parse(data);
      let { id, name, mobile, alt_mobile, mail, address, business_name, pan, aadhar, gstin, primary_category_name, secondary_category_name, enquiry_type_name, file_no,
             group_name, location, referred_by, status, created_by, timestamp, prefix_id, primary_custom_id } = c;
      this.setState({  id, name, mobile, alt_mobile, mail, address, business_name, pan, aadhar, gstin, primary_category_name, secondary_category_name, enquiry_type_name, file_no,
             group_name, location, referred_by, status, created_by, timestamp, prefix_id, primary_custom_id, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getFields = () => {
    let url = Server() + 'field/getallbyshop.php';
    let data = { id: Shop().id, customer_id: this.props.customerId  };
    $.ajax({url, method: "POST", data, success: (data) => {
      data = JSON.parse(data);
      this.setState({ data });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }});
  }

  render() {
    let data = [];
    for (var i = 0; i < this.state.data.length; i += 2) {
      let x = this.state.data[i];
      let y = null;
      try {
        y = this.state.data[i + 1];
      } catch (e) {}
      let out = y ? (
        <tr>
          <td style={{fontWeight: 'bold', height: 40}}>{x.field}:</td><td>{x.value}</td>
          <td style={{fontWeight: 'bold', height: 40}}>{y.field}:</td><td>{y.value}</td>
        </tr>
      ) : (
        <tr>
          <td style={{fontWeight: 'bold', height: 40}}>{x.field}:</td><td>{x.value}</td>
        </tr>
      );
      data.push(out);
    }
    let status = (this.state.status === "0") ? "InActive" : "Active";
    return (
      <div style={{margin: 20, marginTop: '5%'}}>
        <Load load={this.state.load} message={this.state.message}/>
        <center>
          <table borderless border='1' width='70%'>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Name:</td><td>{this.state.name}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Mobile:</td><td>{this.state.mobile}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Alternate Mobile:</td><td>{this.state.alt_mobile}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Mail:</td><td>{this.state.mail}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Name:</td><td>{this.state.name}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Mobile:</td><td>{this.state.mobile}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 50}}>Address:</td><td>{this.state.address}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Business Name:</td><td>{this.state.business_name}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Pan:</td><td>{this.state.pan}</td>
              <td style={{fontWeight: 'bold', height: 40}}>GSTIN:</td><td>{this.state.gstin}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Aadhar:</td><td>{this.state.aadhar}</td>
              <td style={{fontWeight: 'bold', height: 40}}>primary Category:</td><td>{this.state.primary_category_name}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>secondary Category:</td><td>{this.state.secondary_category_name}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Enquiry Type:</td><td>{this.state.enquiry_type_name}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Group Name:</td><td>{this.state.group_name}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Location:</td><td>{this.state.location}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Referred By:</td><td>{this.state.referred_by}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Created By:</td><td>{this.state.created_by}</td>
            </tr>
            <tr>
              <td style={{fontWeight: 'bold', height: 40}}>Added on:</td><td>{this.state.timestamp}</td>
              <td style={{fontWeight: 'bold', height: 40}}>Id</td><td>{this.state.prefix_id}-{this.state.primary_custom_id}</td>
            </tr>
            { data }
          </table>
        </center>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  customerId: state.customerId,
});

export default connect(mapStateToProps, null)(CustomerAllDetails);
