import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, Icon } from 'semantic-ui-react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import Load from '../../util/load';

import { assignPrimaryId } from '../../redux/actions';

class PrimaryCategory extends Component {

  state = { data: [] }

  componentDidMount() {
    this.getPrimaryCategory();
  }

  getPrimaryCategory = () => {
    let url = Server() + "primarycategory/getallbyshop.php";
    let data = {id: Shop().id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading PrimaryCategory"});
    }, success: (data) => {
      this.setState({load: false});
      data = JSON.parse(data);
      this.setState({data});
    }, error: (err) => {
      console.log(err);
      alert(err);
    }});
  }

  render() {
    let { assignPrimaryId } = this.props;
    let data = this.state.data.map((p) => {
      return (
        <MDBTableBody>
          <tr>
            <td>{p.id}</td>
            <td>{p.name}</td>
            <td>{p.prefix_id}</td>
            <td>{p.created_by}</td>
            <td>{p.timestamp}</td>
            <td><Link to="/primary-categories/edit" onClick={() => assignPrimaryId(p.id)}>
              <Icon style={{color: '#2185d0'}} name='edit' />
            </Link></td>
          </tr>
        </MDBTableBody>
      )
    });
    return(
      <div style ={{margin: 10}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Link to ="/primary-categories/add" style={{float: 'right', marginBottom: 20}} onClick={() => assignPrimaryId(0)}>
          <Button icon labelPosition='left' primary><Icon name='user' />Add Primary Category</Button>
        </Link>
        <center>
          <MDBTable borderless border="1" width='100%'>
            <MDBTableHead>
              <tr class="table-title">
                <th>Id</th>
                <th>Name</th>
                <th>Perfix Id</th>
                <th>Created By</th>
                <th>Added On</th>
                <th>Edit</th>
              </tr>
            </MDBTableHead>
            { data }
          </MDBTable>
        </center>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  assignPrimaryId: (id) => dispatch(assignPrimaryId(id)),
});

export default connect(null, mapDispatchToProps)(PrimaryCategory);
