import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button , Form, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';

import Server from '../../function/server';
import Shop from '../../function/shop';
import UserId from '../../function/userid';
import Load from '../../util/load';
import { assignPrimaryId, assignEnquiryId, assignSecondaryId,assignGroupId } from '../../redux/actions';

class AddCustomer extends Component {

  state = { primaryCategories: [], secondaryCategories: [], enquiryTypes: [], alternateCategories: [], groups: [], workTypes: [] };

  handleChange = (type) => (e, { value }) => {
    this.setState({[type]: value});
  }

  emptyState = () => {
    this.setState({
      name: "", mobile: "", alt_mobile: "", mail: "", address: "", primaryCategory: "", secondaryCategory: "", alternate_category: "",
        enquiryType: "", business_name: "", gstin: "", pan: "", aadhar: "", group_id: "", location: "", referred_by: "", status: ""
    });
  }

  componentDidMount() {
    this.getAddCustomerInfo();
    this.getGroup();
    this.getAlternate();
    this.getWorkType();
    this.emptyState();
    let { customerId } = this.props;
    if (!isNaN(customerId) && customerId > 0) {
      this.getCustomer(customerId);
    }
  }

  getCustomer = (id) => {
    let url = Server() + "customer/getbyid.php";
    $.ajax({url, method: "POST", data: {id}, beforeSend: () => {
      this.setState({load: true, message: "loading Edit"});
    }, success: (data) => {
      this.setState({load: false});
      let c = JSON.parse(data);
      let {id, name, mobile, alt_mobile, mail, address, primary_category, secondary_category, enquiry_type, alternate_category, business_name, group_id, location,
          gstin, pan, aadhar, referred_by, status} = c;
      this.setState({id, name, mobile, alt_mobile, mail, address, primaryCategory: primary_category, location, referred_by,
        secondaryCategory: secondary_category, enquiryType: enquiry_type, alternate_category, group_id, business_name, gstin, pan, aadhar, status, edit: true});
    }, error: (err) => {
      console.log(err);
      alert("Error");
    }})
  }

  getAddCustomerInfo = () => {
    let url = Server() + "customer/getaddcustomerinfobyshop.php";
    let data = {id: Shop().id};
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Loading"});
    }, success: (data) => {
      data = JSON.parse(data);
      let primaryCategories = [], secondaryCategories = [], enquiryTypes = [];
      data.map((d) => {
        if (d.type === "primary") primaryCategories.push(d);
        else if (d.type === "secondary") secondaryCategories.push(d);
        else enquiryTypes.push(d);
      });
      this.setState({ primaryCategories, secondaryCategories, enquiryTypes, load: false });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getGroup = () => {
    let url = Server() + "group/getallbyshop.php";
    let data = {id: Shop().id};
    $.ajax({ url, method: "POST", data, success: (data) => {
      let groups = JSON.parse(data);
      this.setState({ groups });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getAlternate = () => {
    let url = Server() + "alternatecategory/getallbyshop.php";
    let data = {id: Shop().id};
    $.ajax({ url, method: "POST", data, success: (data) => {
      let alternateCategories = JSON.parse(data);
      this.setState({ alternateCategories });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  getWorkType = () => {
    let url = Server() + 'worktype/getallbyshop.php';
    let data = { id: Shop().id };
    $.ajax({url, method: "POST", data,  success: (data) => {
      let workTypes = JSON.parse(data);
      this.setState({ workTypes });
    }, error: (e) => {
      console.log(e);
      alert("Error");
    }})
  }

  add = () => {
    let { name, mobile, alt_mobile, mail, address, primaryCategory, secondaryCategory,
      enquiryType, alternate_category, group_id, business_name, gstin, pan, aadhar, status, referred_by, location } = this.state;
    if (!secondaryCategory || secondaryCategory.length < 1) secondaryCategory = 0;
    if (!enquiryType || enquiryType.length < 1) enquiryType = 0;
    if (!group_id || group_id.length < 1) group_id = 0;
    if(!name || name.length < 1 || !mobile || mobile.length < 1 || !alt_mobile || alt_mobile.length < 1 ||
       !mail || mail.length < 1 || !address || address.length < 1 || primaryCategory < 1 || !alternate_category || alternate_category.length < 1
       ||  !business_name || business_name.length < 1 || status.length < 0 ) {
      alert("Invalid Information"); return;
    }
    else if(!gstin || gstin.length < 12 ) {
      alert("Invaild Gstin"); return;
    }
    let data = {shop_id: Shop().id, name, mobile, alt_mobile, mail, address, primary_category: primaryCategory, location, referred_by,
      secondary_category: secondaryCategory, enquiry_type: enquiryType, alternate_category, business_name, gstin, pan, group_id, aadhar, status, created_by: UserId()};
    let url = Server() + "customer/add.php";
    if (this.state.edit) {
      data = {id: this.state.id, name, mobile, alt_mobile, mail, address, primary_category: primaryCategory, location, referred_by,
        secondary_category: secondaryCategory, enquiry_type: enquiryType, alternate_category, group_id, business_name, gstin, pan, aadhar, status};
      url = Server() + "customer/edit.php";
    }
    $.ajax({url, method: "POST", data, beforeSend: () => {
      this.setState({load: true, message: "Adding"});
    }, success: (data) => {
      this.setState({load: false});
      if(data == "1" || data == "true") {
        alert(this.state.edit ? "Updated Successfully" : "customer Info Added Successfully");
        this.emptyState();
      } else {
        alert("Failed to Add"); console.log(data);
      }
    }, error: (e)=> {
      console.log(e);
      alert("Error");
    }})
  }

  render() {
    let { assignEnquiryId, assignPrimaryId, assignSecondaryId, assignGroupId } = this.props;
    let primaryCategoryOptions = this.state.primaryCategories.map((pc) => {
      return {key: pc.id, value: pc.id, text: pc.name};
    });
    let secondaryCategoryOptions = [];
    for (var i = 0; i < this.state.secondaryCategories.length; i++) {
      let sc = this.state.secondaryCategories[i];
      if (sc.master == this.state.primaryCategory) {
        secondaryCategoryOptions.push({key: sc.id, value: sc.id, text: sc.name});
      }
    }
    let enquiryTypeOptions = this.state.enquiryTypes.map((et) => {
      return {key: et.id, value: et.id, text: et.name};
    });
    let groupOptions = this.state.groups.map((g) => {
      return {key: g.id, value: g.id, text: g.group_name};
    });
    let alternateOptions = this.state.alternateCategories.map((a) => {
      return { key: a.id, value: a.id, text: a.name};
    });
    let workOptions = this.state.workTypes.map((a) => {
      return { key: a.id, value: a.id, text: a.type};
    });
    let statusOptions = [{key: 0, value: "0", text: "InActive"}, {key: 1, text: "Active", value: "1"}];
    return(
      <div style={{margin: 20}}>
        <Load load={this.state.load} message={this.state.message}/>
        <Form>
          <Form.Group widths='equal'>
            <Form.Input fluid label='Name' placeholder='Name' required
              value={this.state.name} onChange={this.handleChange("name")}/>
            <Form.Input fluid label='Mobile' placeholder='Mobile' required
              value={this.state.mobile} onChange={this.handleChange("mobile")}/>
            <Form.Input fluid label='Alt Mobile' placeholder='Alt Mobile' required
              value={this.state.alt_mobile} onChange={this.handleChange("alt_mobile")}/>
            <Form.Input fluid label='Mail Id' placeholder='Mail Id' required
              value={this.state.mail} onChange={this.handleChange("mail")} />
          </Form.Group>
          <Form.TextArea label='Address' placeholder='Enter Customer Address Address' required
            value={this.state.address} onChange={this.handleChange("address")}/>
          <Form.Group widths='equal'>
            <Form.Input fluid label='Business Name' placeholder='Business Name' required
              value={this.state.business_name} onChange={this.handleChange("business_name")}/>
            <Form.Input fluid label='GSTIN' placeholder='GSTIN'
              value={this.state.gstin} onChange={this.handleChange("gstin")}/>
            <Form.Input fluid label='PAN' placeholder='PAN'
              value={this.state.pan} onChange={this.handleChange("pan")}/>
            <Form.Input fluid label='Aadhar' placeholder='Aadhar'
              value={this.state.aadhar} onChange={this.handleChange("aadhar")}/>
          </Form.Group>
          <Form.Group >
            <Form.Select fluid label='Primary Category' placeholder='Primary Category' clearable required
              onChange={this.handleChange("primaryCategory")} value={this.state.primaryCategory} options={primaryCategoryOptions} width={4}/>
            <Form.Select fluid label='Secondary Category' placeholder='Secondary Category' clearable
              onChange={this.handleChange("secondaryCategory")} value={this.state.secondaryCategory} options={secondaryCategoryOptions} width={4}/>
            <Form.Select fluid label='Enquiry Type' placeholder='Enquiry Type' clearable
              onChange={this.handleChange("enquiryType")} value={this.state.enquiryType} options={enquiryTypeOptions} width={4}/>
            <Form.Select fluid label='Alternate Category' placeholder='Alternate Category' clearable multiple required
              onChange={this.handleChange("alternate_category")} value={this.state.alternate_category} options={primaryCategoryOptions} width={4}/>
          </Form.Group>
          <Form.Group style={{display: 'none'}}>
            <Form.Select label='Work Type' placeholder='Choose Work Type' clearable
              value={this.state.workType} onChange={this.handleChange("workType")} options={workOptions} width={4}/>
          </Form.Group>
          <Form.Group width='equals'>
            <Form.Select fluid label='Status' placeholder='Status' clearable required
              onChange={this.handleChange("status")} value={this.state.status} options={statusOptions} width={4}/>
            <Form.Select fluid label="Group" placeholder='Choose Group' clearable
              onChange={this.handleChange("group_id")} value={this.state.group_id} options={groupOptions} width={4} />
            <Form.Input fluid label="Location" placeholder='Enter Customer Location'
              onChange={this.handleChange("location")} value={this.state.location} width={4} />
            <Form.Input fluid label="Referred By" placeholder='Enter Referrer'
              onChange={this.handleChange("referred_by")} value={this.state.referred_by} width={4} />
          </Form.Group>

          <Form.Group style={{marginLeft: '48%'}}>
            <Button primary onClick={this.add}>{this.state.edit ? "Save" : "Add"}</Button>
            {
              this.state.edit ? <Link to="/customer/editfields"><Button primary style={{marginLeft: '5%', width: 150}}>Edit Other Fields</Button></Link> : ""
            }
          </Form.Group>
          {
            this.state.edit != true &&
            <div style={{float: "Right", marginBottom: '2%'}}>
              <div style={{fontWeight: 'bold'}}>SHORTCUT TO ADD PAGES</div>
              <br />
              <Link to="/primary-categories/add" onClick={() => assignPrimaryId(0)}><Button  inverted primary>Primary</Button></Link>
              <Link to="/secondary-categories/add" onClick={() => assignSecondaryId(0)}><Button  inverted primary>Secondary</Button></Link>
              <Link to="/enquiry-types/add" onClick={() => assignEnquiryId(0)}><Button  inverted primary>Enquiry</Button></Link>
              <Link to="/group/add" onClick={() => assignGroupId(0)}><Button  inverted primary>Group</Button></Link>
            </div>
          }
        </Form>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  customerId: state.customerId,
});

const mapDispatchToProps = (dispatch) => ({
  assignPrimaryId: (id) => dispatch(assignPrimaryId(id)),
  assignEnquiryId: (id) => dispatch(assignEnquiryId(id)),
  assignSecondaryId: (id) => dispatch(assignSecondaryId(id)),
  assignGroupId: (id) => dispatch(assignGroupId(id)),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(AddCustomer);
